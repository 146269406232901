

  .customStatContainer {
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    gap: 8px;
    margin-bottom: 24px;
    overflow: hidden;

  }
  .customCard .ant-card-body {
    display: flex;
    gap: 0 12px;
    padding: 12px 4px;
  }
  .customCard-stats .ant-card-head {
    padding: 0 12px;
    margin: 0;
    min-height: 0;
  }
  .customCard-stats .ant-card-head-title{
    padding: 4px;
  }
  .customCard-stats{
    border-radius: 0!important;
  }
  .customCard-stats .ant-card-body{
    gap: 0 16px;
    padding: 8px 0;
  }
  .ant-btn, .ant-select-selector, .ant-select-dropdown {
    border-radius: 0!important
  }



@colors-onError: rgba(255, 255, 255, 1);@colors-onAccent: rgba(255, 255, 255, 1);@colors-onAmbient: rgba(255, 255, 255, 1);@colors-onSurface: rgba(36, 35, 35, 1);@colors-onBackground: rgba(36, 35, 35, 1);@colors-background: rgba(250, 250, 250, 1);@colors-surfaceVariant: rgba(255, 255, 255, 0.5);@colors-surface: rgba(255, 255, 255, 1);@colors-gray: rgba(36, 35, 35, 0.6);@colors-grayUltraLight: rgba(36, 35, 35, 0.05);@colors-grayLight: rgba(36, 35, 35, 0.25);@colors-success: rgba(79, 139, 60, 1);@colors-ambient: rgba(86, 178, 233, 1);@colors-successVariant: rgba(221, 238, 216, 1);@colors-ambientVariant: rgba(0, 128, 200, 1);@colors-accentLight: rgba(250, 235, 205, 1);@colors-accent: rgba(239, 182, 62, 1);@colors-accentVariant: rgba(229, 113, 44, 1);@colors-error: rgba(234, 51, 35, 1);@primary-color: rgba(239, 182, 62, 1);@error-color: rgba(234, 51, 35, 1);@text-color: rgba(36, 35, 35, 1);@info-color: rgba(86, 178, 233, 1);@background-color: rgba(250, 250, 250, 1);@disabled-color: rgba(36, 35, 35, 0.25);@border-radius-base: 16px;